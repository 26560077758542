const metaUrl = encodeURIComponent(document.querySelector('meta[property="og:url"]') ? document.querySelector('meta[property="og:url"]').getAttribute('content') : null);
const metaImage = encodeURIComponent(`https:${document.querySelector('meta[property="og:image"]') ? document.querySelector('meta[property="og:image"]').getAttribute('content') : '//aizel.ru'}`);
const metaTitle = encodeURIComponent(document.querySelector('meta[property="og:title"]') ? document.querySelector('meta[property="og:title"]').getAttribute('content') : null);
const metaDescription = encodeURIComponent(document.querySelector('meta[property="og:description"]') ? document.querySelector('meta[property="og:description"]').getAttribute('content') : null);

const config = {
    activeClass: 'is-active',
    selectedClass: 'is-selected',
    hiddenClass: 'is-hidden',
    visibleClass: 'is-visible',
    selectDefault: {
        locale: 'ru',
        height: 200,
        descriptions: true,
    },
    socialShare: {
        // fb: `https://www.facebook.com/sharer/sharer.php?u=${metaUrl}&picture=${metaImage}&title=${metaTitle}&description=${metaDescription}`,
        vk: `https://vk.com/share.php?url=${metaUrl}&image=${metaImage}&title=${metaTitle}&description=${metaDescription}`,
        pinterest: `https://pinterest.com/pin/create/button/?url=${metaUrl}&media=${metaImage}&description=${metaDescription}`,
    },
    windowParams: {
        width: 700,
        height: 480,
        toolbar: 'no',
        location: 'no',
        status: 'no',
        left: ((screen.width / 2) - (2 / 2)),
        top: ((screen.height / 2) - (2 / 2)),
    },
};

export default config;
