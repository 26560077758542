class CountdownTimer {
    constructor(options) {
        if (!options.element) {
            return;
        }

        this.options = options;
        this.element = options.element;
        this.duration = options.duration;
        this.granularity = options.granularity || 1000;
        this.running = false;
    }

    start() {
        if (this.running) {
            return;
        }

        this.running = true;

        const that = this;
        const start = Date.now();
        let diff;

        (function timer() {
            diff = that.duration - (((Date.now() - start) / 1000) | 0);

            if (diff > 0) {
                setTimeout(timer, that.granularity);
            } else {
                diff = 0;
                that.running = false;
                that.expired();
            }

            const obj = that.parse(diff);

            that.format(obj.minutes, obj.seconds);
        }());
    }

    expired(event) {
        if (this.options.onExpired) {
            this.options.onExpired(this, event);
        }

        return !this.running;
    }

    parse(seconds) {
        return {
            minutes: (seconds / 60) | 0,
            seconds: (seconds % 60) | 0,
        };
    }

    format(minutes, seconds) {
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        this.element.textContent = `${minutes}:${seconds}`;
    }
}

export default CountdownTimer;
