import Sentry from '@lfg/fabrics/js/functions/sentry';
import 'element-matches';
import 'element-closest';
import {
    Swiper, Navigation, Pagination, Zoom, Scrollbar, Keyboard,
} from 'swiper';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';
import tailSelect from 'tail.select';
import 'tail.select/langs/tail.select-ru';
import IMask from 'imask';
import stickybits from 'stickybits';
import autocomplete from 'autocompleter';
import autocompleteCustom from '@lfg/fabrics/js/functions/autocomplete';
import retailRocketPreloader from '@lfg/fabrics/js/functions/retailRocketPreloader';
import Request from '@lfg/fabrics/js/classes/Request';
import collapse from '@lfg/fabrics/js/functions/collapse';
import renderSearchAutocomplete from '@lfg/fabrics/js/functions/renderSearchAutocomplete';
import Message from '@lfg/fabrics/js/classes/Message';
import HeaderDropdown from '@lfg/fabrics/js/classes/HeaderDropdown';
import Modal from '@lfg/fabrics/js/classes/Modal';
import Tracking from '@lfg/fabrics/js/classes/Tracking';
import MediaQuery from '@lfg/fabrics/js/classes/MediaQuery';
import Map from '@lfg/fabrics/js/classes/Map';
import wishlistUpdate from '@lfg/fabrics/js/functions/wishlistUpdate';
import Filters from '@lfg/fabrics/js/classes/Filters';
import {
    cartAdd, cartUpdate, cartDelete, cartExchange, cartAddFast, cartAddArray, memoryAddCollection, convertingStringQuantityAndPrice
} from '@lfg/fabrics/js/functions/cart';
import formatMoney from '@lfg/fabrics/js/functions/formatMoney';
import getFormData from '@lfg/fabrics/js/functions/getFormData';
import validation from '@lfg/fabrics/js/functions/validate';
import Loader from '@lfg/fabrics/js/classes/loader';
import wrapMarkupModal from '@lfg/fabrics/js/functions/wrapMarkupModal';
import changeSizePrice from '@lfg/fabrics/js/functions/changeSizePrice';
import serializeForm from '@lfg/fabrics/js/functions/serializeForm';
import { initNavTabs } from '@lfg/fabrics/js/functions/tabs';
import socialAuth from '@lfg/fabrics/js/functions/socialAuth';
import Tooltip from '@lfg/fabrics/js/classes/Tooltip';
import { showBraSizeResult, showClothesSizeResult, showStockingsSizeResult } from '@lfg/fabrics/js/functions/sizes';
import getEventPath from '@lfg/fabrics/js/functions/getEventPath';
import boutiques from './boutiques';
import config from './config';
// import Sets from './Sets';
import Sets from '@lfg/fabrics/js/classes/Sets';
import updateProductCounter from "@lfg/fabrics/js/functions/updateProductCounter";
import CountdownTimer from './CountdownTimer';

(function app() {
    /* Сброс скролла браузера */
    if (window.history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }

    document.addEventListener('DOMContentLoaded', () => {
        try {
            const lazy = new LazyLoad({
                elements_selector: '.js-lazyLoad',
                threshold: 500,
                callback_loaded: element => {
                    const wrapper = element.closest('.js-image');
                    const loader = wrapper.querySelector('.js-placeholder');

                    if (wrapper && loader) {
                        loader.remove();
                    }
                },
            });

            function initSticky() {
                stickybits('.js-stickySidebar', {
                    stickyBitStickyOffset: 20,
                });
            }

            initSticky();

            const filter = new Filters();

            tailSelect('.js-select', config.selectDefault);

            Swiper.use([Navigation, Pagination, Zoom, Scrollbar, Keyboard]);

            HeaderDropdown.run();

            retailRocketPreloader();

            initNavTabs();

            const modalContent = document.querySelector('.js-signinModalContent');

            const modalSignin = Modal.init({
                content: document.querySelector('.js-signinModal'),
                popoverElemClasses: '-small',
                onClose: () => {
                    modalContent.innerHTML = '';
                },
            });

            function waitForElement(selector) {
                // eslint-disable-next-line consistent-return
                return new Promise(resolve => {
                    if (document.querySelector(selector)) {
                        return resolve(document.querySelector(selector));
                    }

                    const observer = new MutationObserver(mutations => {
                        if (document.querySelector(selector)) {
                            resolve(document.querySelector(selector));
                            observer.disconnect();
                        }
                    });

                    observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });
                });
            }

            function renderCountdown() {
                const countdown = document.querySelector('.js-codeCountdown');
                const codeInput = document.querySelector('.js-confirmCode');
                const codeSubmit = document.querySelector('.js-submitConfirm');

                if (countdown) {
                    new CountdownTimer({
                        element: countdown,
                        duration: countdown.dataset.time,
                        onExpired: () => {
                            countdown.parentNode.addEventListener('click', () => {
                                codeInput.value = '';
                                codeInput.setAttribute('disabled', 'disabled');
                                codeSubmit.click();
                                codeSubmit.setAttribute('disabled', 'disabled');
                            });

                            countdown.parentNode.setAttribute('class', 'u-textUnderline u-textSmaller u-cursorPointer u-marginTopX2');
                            countdown.parentNode.innerText = 'Получить новый код';
                        },
                    }).start();
                }
            }

            function handleRecaptcha() {
                const script = document.createElement('script');

                script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?hl=ru&onload=onloadCallback&render=explicit');
                script.setAttribute('async', '');
                script.setAttribute('defer', '');
                waitForElement('.g-recaptcha').then(element => {
                    document.querySelector('.js-submitAuthForm').appendChild(script);

                    const verifyCallback = recaptchaResponse => {
                        if (recaptchaResponse.length > 0) {
                            document.getElementsByName('challenge[code]')[0].value = recaptchaResponse;

                            const form = document.querySelector('.js-submitAuthForm');

                            Request.sendForm(form, {
                                loader: [form],
                                resolve: response => handleAuthFormResponse(form, response),
                            });
                        }
                    };

                    const onloadCallback = () => {
                        const { sitekey } = element.dataset;
                        // eslint-disable-next-line no-undef
                        grecaptcha.render('g-recaptcha', {
                            sitekey,
                            callback: verifyCallback,
                        });
                    };

                    window.onloadCallback = onloadCallback;
                });
            }

            function flushObjectFields(object, fieldsToFlush) {
                return fieldsToFlush.forEach(field => {
                    delete object[field];
                });
            }

            function getDeliveryFieldsToFlush(formData) {
                const keys = Object.keys(formData);
                const re = /checkout\[split\]\[\d+\]\[(delivery_date|delivery_time_from|delivery_time_to|shipping_date|shipping_cost)\]/;
                const filteredKeys = keys.map(k => k.search(re));
                const fieldIndexesToRemove = filteredKeys
                    .map((k, i) => (k === 0 ? i : null))
                    .filter(i => i !== null);
                const fieldsToFlush = fieldIndexesToRemove.map(i => keys[i]);

                return fieldsToFlush;
            }

            function changeSizePriceSelect(element) {
                const productCard = element.closest('.js-productCard');
                const stockId = productCard.querySelector('.js-stockId');
                const stockPrice = productCard.querySelector('.js-stockPrice');
                stockId.value = element.value;
                stockPrice.value = element.dataset.price;
            }

            function handleAuthFormResponse(authForm, response) {
                authForm.classList.remove('is-valid');

                if (response.redirectUrl) {
                    modalSignin.close();
                    window.location = response.redirectUrl;
                } else if (response.confirmPhoneWidget) {
                    modalContent.innerHTML = response.confirmPhoneWidget;

                    const phoneInput = document.querySelector('.js-signinPhone');

                    if (phoneInput) {
                        initMask();
                        phoneInput.focus();
                    }

                    if (document.querySelector('#g-recaptcha')) {
                        handleRecaptcha();
                    }

                    if (modalContent.querySelector('.js-confirmCode')) {
                        renderCountdown();
                        modalContent.querySelector('.js-confirmCode').focus();
                    }

                    document.querySelectorAll('.c-modal.-active form').forEach(element => {
                        validation.bind(element);
                    });
                }
            }

            function submitForm(event) {
                const self = event.target;
                // const buttonSubmit = self.querySelector('button[type=submit]') ? self.querySelector('button[type=submit]') : self.querySelector('.js-submitFormButton');
                const { responseType } = self.dataset;

                if (self.classList.contains('is-valid')) {
                    /* Отправка поля телефона без применённой маски */
                    if (document.querySelector('.js-maskPhoneFixed')) {
                        Array.from(document.querySelectorAll('.js-maskPhoneFixed')).forEach(element => {
                            element.value = element.zipcodeMask.unmaskedValue;
                        })
                    }

                    Request.sendForm(self, {
                        // loader: responseType === 'widget' ? [modalContent] : [buttonSubmit, {loaderButton: true}],
                        loader: [self],
                        resolve: response => {
                            self.classList.remove('is-valid');

                            if (response.redirectUrl) {
                                window.location = response.redirectUrl;
                                return;
                            }

                            if (responseType === 'widget') {
                                if (response.widgets[Object.keys(response.widgets)[0]].close) {
                                    self.querySelector('.js-modalClose').click();
                                    document.querySelector('.js-contentMain').innerHTML = response.widgets[Object.keys(response.widgets)[1]].html;
                                    reloadCheckout();
                                } else {
                                    const container = self.dataset.container ? document.querySelector(`.${self.dataset.container}`) : self;

                                    container.innerHTML = response.widgets[Object.keys(response.widgets)[0]].html;

                                    document.querySelectorAll('.c-modal.-active form').forEach(element => {
                                        validation.bind(element);
                                    });
                                }
                            } else {
                                const revealContainer = self.closest('.js-revealContainer');

                                if (revealContainer) {
                                    if (response.success === true) {
                                        if (self.dataset.password !== undefined) {
                                            toggleReveal(self);
                                            Message.show(revealContainer, response, '', true);
                                            self.querySelector('[name=\'changepassworddata[password]\']').value = '';
                                            self.querySelector('[name=\'changepassworddata[passwordRepeat]\']').value = '';
                                        } else {
                                            window.location.reload();
                                        }
                                    } else {
                                        Message.show(revealContainer, response);
                                    }
                                } else if (response.success === true) {
                                    if (self.dataset.result === 'modal') {
                                        Modal.init({
                                            content: self.querySelector('.js-result'),
                                            popoverElemClasses: '-small',
                                        }).open();
                                    } else {
                                        Message.show(self, response);
                                    }
                                } else {
                                    Message.show(self, response);
                                }
                            }
                        },
                    });
                }
            }

            function submitAuthForm(event) {
                const form = event.target;

                if (form.querySelector('.js-signinError')) {
                    form.querySelector('.js-signinError').remove();
                }

                if (!form.classList.contains('is-valid')) {
                    return;
                }

                Request.sendForm(form, {
                    loader: [form],
                    resolve: response => handleAuthFormResponse(form, response),
                });
            }

            function saveAddress(event) {
                const self = event.target;
                const action = self.getAttribute('action');

                document.dispatchEvent(new CustomEvent('updateAddress'));

                const checkoutData = getFormData(document.querySelectorAll('.js-checkoutField'));
                const data = Object.assign(checkoutData, serializeForm(self));

                const deliveryFieldsToFlush = getDeliveryFieldsToFlush(checkoutData);
                flushObjectFields(checkoutData, deliveryFieldsToFlush);

                if (self.classList.contains('is-valid')) {
                    Request.post(action, data, {
                        loader: [self],
                        resolve: response => {
                            self.classList.remove('is-valid');

                            if (response.widgets[Object.keys(response.widgets)[0]].close) {
                                self.querySelector('.js-modalClose').click();
                                document.querySelector('.js-contentMain').innerHTML = response.widgets[Object.keys(response.widgets)[1]].html;
                                reloadCheckout();
                            } else {
                                const container = self.dataset.container ? document.querySelector(`.${self.dataset.container}`) : self;

                                container.innerHTML = response.widgets[Object.keys(response.widgets)[0]].html;
                                document.dispatchEvent(new CustomEvent('reloadAutocomplete'));
                            }
                        },
                    });
                }
            }

            function manageVoucher(event) {
                const self = event.target;
                const buttonSubmit = self.querySelector('button[type=submit]');
                const amountBalance = document.querySelector('.js-balanceAmount');
                const container = self.querySelector('.js-voucherContainer');
                const containerCopy = container.cloneNode(true);

                let voucherCode = null;
                const { operation } = buttonSubmit.dataset;
                let { method } = self;
                let { action } = self;

                if (operation === 'check') {
                    method = 'get';
                    action = '/account/check-voucher/';
                    voucherCode = self.querySelector('.js-voucherCode').value;
                } else if (operation === 'apply') {
                    method = 'post';
                    action = '/account/apply-voucher/';
                    voucherCode = self.dataset.code;
                } else if (operation === 'number') {
                    method = 'get';
                    action = '/account/check-voucher-number/';
                    voucherCode = self.querySelector('.js-voucherCode').value;
                }

                let formData = serializeForm(self);

                const data = operation === 'number' ? {voucherNumber: voucherCode} : {voucherCode};

                formData = Object.assign(formData, data);

                if (self.classList.contains('is-valid')) {
                    Request[method](action, formData, {
                        loader: [buttonSubmit, { loaderButton: true }],
                        error: () => {
                            Message.show(event.target, 'Подарочная карта не найдена');
                        },
                        resolve: response => {
                            if (self.querySelector('.js-voucherCode')) {
                                self.dataset.code = self.querySelector('.js-voucherCode').value;
                            }

                            if (self.querySelector('.m-info')) {
                                self.querySelector('.m-info').remove();
                            }

                            event.target.classList.remove('is-valid');

                            if (response.success === true) {
                                if (response.isAuth) {
                                    if (operation === 'check') {
                                        if (self.querySelector('.c-info')) {
                                            self.querySelector('.c-info').remove();
                                        }
                                        container.innerHTML = `<div class="l-cell -size12XS -size12S">
<div class="a-text h-marginBottomM">
Доступно к&nbsp;зачислению:
    <span class="c-amount -bold">
        <span class="c-amount__amount js-cardAmount">${formatMoney(response.balance)}</span>
     <span class="c-amount__currency">RUB</span>
    </span>
</div>
</div>
<div class="l-cell -size12XS -size3S">
    <input class="c-input -wide js-voucherBalance" type="text" name="voucherAmount" placeholder="Сумма к зачислению" value="${response.balance}" data-max="${response.balance}" data-validate="require number quantity notzero" autocomplete="off"/>
<div class="a-text -secondary -featured js-formError"></div>
</div>
<div class="l-cell -size12XS -size9S">
    <button class="c-button -main -wideXS h-marginXSTop h-marginRightXS" type="submit" data-operation="apply">Зачислить на счет</button>
    <button class="c-button -wideXS h-marginXSTop js-undoCheck" type="button">Отменить</button>
    <div class="u-textSmaller u-textMuted">
        Нажимая кнопку "Зачислить на счет" вы переводите средства с баланса подарочного 
        сертификата на свой Личный счет в интернет-магазине. Средства Личного счета доступны только для оплаты заказов 
        интернет-магазина (до 100% от суммы заказа), но их нельзя тратить в бутике оффлайн.
    </div>
</div>`;

                                    document.querySelector('.js-voucherBalance').focus();
                                    validation.bind(document.querySelector('.js-formVoucher'));

                                    document.querySelector('.js-undoCheck').addEventListener('click', () => {
                                        container.innerHTML = containerCopy.innerHTML;
                                    }, false);
                                } else if (operation === 'number') {
                                    if (self.querySelector('.c-info')) {
                                        self.querySelector('.c-info').remove();
                                    }
                                    container.innerHTML = `<div class="l-cell -size12XS -size12S">
<div class="a-text h-marginBottomM">
Доступно к&nbsp;зачислению:
    <span class="c-amount -bold">
        <span class="c-amount__amount js-cardAmount">${formatMoney(response.balance)}</span>
     <span class="c-amount__currency">RUB</span>
    </span>
</div>
<div class="l-cell -size12XS -size9S">
<button class="c-button -wideXS h-marginXSTop js-undoCheck" type="button">Проверить еще одну карту</button>
</div>
</div>`;
                                    validation.bind(document.querySelector('.js-formVoucher'));

                                    document.querySelector('.js-undoCheck').addEventListener('click', () => {
                                        container.innerHTML = containerCopy.innerHTML;
                                    }, false);
                                } else if (operation === 'apply') {
                                    amountBalance.innerHTML = `<div class="c-divider h-marginTopM h-marginBottomM u-hiddenS"></div>
                      <div class="a-text h-marginBottomXS">Остаток на личном счете:</div>
                      <div class="c-amount -bold">
                        <div class="c-amount__amount">${formatMoney(response.client_balance)}</div>
                        <div class="c-amount__currency">RUB</div>
                      </div>`;

                                        if (response.voucher_balance === 0) {
                                            container.innerHTML = `<div class="c-info -muted h-marginTopM js-textSuccess">
            <svg class="c-icon -info">
                <use xlink:href="/assets/img/icons.svg#info"></use>
            </svg>
            <div class="c-info__text -mutedMore">На ваш личный счет зачислена полная сумма с&nbsp;карты</div>
        </div>`;
                                        } else {
                                            document.querySelector('.js-cardAmount').innerHTML = formatMoney(response.voucher_balance);
                                            document.querySelector('.js-voucherBalance').value = response.voucher_balance;
                                            document.querySelector('.js-voucherBalance').focus();
                                            Message.show(event.target, response);
                                        }
                                    }
                                } else {
                                    if (self.querySelector('.c-info')) {
                                        self.querySelector('.c-info').remove();
                                    }
                                    container.innerHTML = `<div class="l-cell -size12XS -size12S">
<div class="a-text h-marginBottomM">
Доступно к&nbsp;зачислению:
    <span class="c-amount -bold">
        <span class="c-amount__amount js-cardAmount">${formatMoney(response.balance)}</span>
     <span class="c-amount__currency">RUB</span>
    </span>
</div>
<div class="l-cell -size12XS -size9S">
<button class="c-button -wideXS h-marginXSTop js-undoCheck" type="button">Проверить еще одну карту</button>
</div>
</div>`;
                                    validation.bind(document.querySelector('.js-formVoucher'));

                                    document.querySelector('.js-undoCheck').addEventListener('click', () => {
                                        container.innerHTML = containerCopy.innerHTML;
                                    }, false);
                                }
                            } else {
                                Message.show(event.target, response);
                                setTimeout(() => {
                                    if (response.redirectUrl) {
                                        window.location.href = response.redirectUrl;
                                    } else {
                                        window.location.reload();
                                    }
                                }, 2000);
                            }
                        },
                    });
                }
            }

            function togglePasswordForm() {
                document.querySelector('.js-changePasswordButton').classList.toggle('u-hidden');
                document.querySelector('.js-changePasswordForm').classList.toggle('u-hidden');
            }

            function getQuery(query, format, city, street, house) {
                let newQuery = query;
                if (format === 'house') {
                    newQuery = [city.value, street.value, house.value];
                } else {
                    newQuery = [city.value, street.value];
                }

                return newQuery.join();
            }

            let cityCorrect = false;
            let streetCorrect = false;
            let houseCorrect = false;

            function resetAddressFieldsChecks() {
                cityCorrect = false;
                streetCorrect = false;
                houseCorrect = false;
            }

            function addressAutocomplete() {
                const autocompleteField = document.querySelectorAll('.js-autocompleteAddress');

                /*  Сбрасываем проверку корректности полей
                при пользовательской манипуляции полями ввода */
                autocompleteField.forEach(element => {
                    element.addEventListener('input', () => {
                        resetAddressFieldsChecks();
                    });
                });

                autocompleteField.forEach(element => {
                    const { format } = element.dataset;
                    const { callback } = element.dataset;
                    const city = document.querySelector('.js-city');
                    const cityId = document.querySelector('.js-cityId');
                    const street = document.querySelector('.js-street');
                    const house = document.querySelector('.js-house');
                    const apartment = document.querySelector('.js-apartment');
                    const zipcode = document.querySelector('.js-zipcode');

                    autocomplete({
                        input: element,
                        minLength: 1,
                        debounceWaitMs: 300,
                        emptyMsg: 'Ничего не найдено',
                        fetch(text, update) {
                            let query = [];
                            let url = null;
                            let data = null;

                            query = getQuery(query, format, city, street, house);

                            if (format === 'city') {
                                url = '/site/search-city/';
                                data = { query: element.value };
                            } else {
                                url = '/search/address/';
                                data = { query, format };
                            }

                            element.classList.add('-loading');

                            Request.post(url, data, {
                                loader: false,
                                resolve: response => {
                                    element.classList.remove('-loading');
                                    update(response);
                                },
                                error: () => {
                                    element.classList.remove('-loading');
                                },
                            });
                        },
                        render(item) {
                            const div = document.createElement('div');

                            if (format === 'city') {
                                div.setAttribute('title', item.label);
                                div.textContent = item.label;
                            } else {
                                div.setAttribute('title', item.value);
                                div.textContent = item.value;
                            }

                            return div;
                        },
                        onSelect(item, inputfield) {
                            const newInputField = inputfield;

                            if (format === 'city') {
                                newInputField.value = item.city.name;
                                cityCorrect = true;
                                streetCorrect = false;
                                houseCorrect = false;
                                cityId.value = item.city.id;
                                street.value = '';
                                house.value = '';
                                apartment.value = '';

                                if (zipcode) {
                                    zipcode.value = '';
                                }

                                if (callback && callback === 'refund') {
                                    document.dispatchEvent(new CustomEvent('fillRefundDates'));
                                }
                            } else if (format === 'street') {
                                newInputField.value = item.value;
                                cityCorrect = true;
                                streetCorrect = true;
                                houseCorrect = false;
                                house.value = '';
                                apartment.value = '';

                                if (zipcode) {
                                    zipcode.value = '';
                                }
                            } else if (format === 'house') {
                                newInputField.value = item.value;
                                cityCorrect = true;
                                streetCorrect = true;
                                houseCorrect = true;

                                if (zipcode) {
                                    zipcode.value = '';
                                }
                            } else {
                                newInputField.value = item.value;
                            }

                            if (item.postcode && item.postcode !== null) {
                                if (zipcode) {
                                    zipcode.value = item.postcode;
                                }
                            }

                            document.dispatchEvent(new CustomEvent('updateAddress'));
                        },
                    });
                });
            }

            addressAutocomplete();

            document.addEventListener('updateAddress', () => {
                const isAddressCorrect = document.querySelector('.js-addressValidation');

                if (isAddressCorrect) {
                    if (cityCorrect && streetCorrect && houseCorrect) {
                        isAddressCorrect.value = 1;
                    } else if (isAddressCorrect.value !== 1) {
                        isAddressCorrect.value = 0;
                    }
                }
            });

            if (document.documentElement.dataset.userClickEvents) {
                return;
            }

            document.documentElement.dataset.userClickEvents = true;

            function initImageFullscreen(index) {
                const imageFullscreen = new Swiper('.js-sliderFullscreen', {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    watchOverflow: true,
                    zoom: true,
                    wrapperClass: 'js-sliderWrapper',
                    slideClass: 'js-sliderSlide',
                    navigation: {
                        nextEl: '.js-sliderFullscreenNext',
                        prevEl: '.js-sliderFullscreenPrev',
                    },
                    // scrollbar: {
                    //     el: '.js-sliderFullscreenScrollbar',
                    //     draggable: true,
                    // },
                    on: {
                        init() {
                            this.slideTo(index, 0);
                        },
                    },
                });
            }

            MediaQuery.set('screen and (max-width:1279.98px)', {
                match: () => {
                    if (document.querySelector('.js-productSlider')) {
                        new Swiper('.js-productSlider', {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            watchOverflow: true,
                            breakpointsInverse: true,
                            wrapperClass: 'js-sliderWrapper',
                            slideClass: 'js-sliderSlide',
                            scrollbar: {
                                el: '.js-productSliderScrollbar',
                                draggable: true,
                            },
                            breakpoints: {
                                767: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                },
                            },
                        });
                    }

                    if (document.querySelector('.js-relevantSlider')) {
                        new Swiper('.js-relevantSlider', {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            watchOverflow: true,
                            breakpointsInverse: true,
                            wrapperClass: 'js-sliderWrapper',
                            slideClass: 'js-sliderSlide',
                            scrollbar: {
                                el: '.js-relevantSliderScrollbar',
                                draggable: true,
                            },
                            breakpoints: {
                                767: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                },
                            },
                        });
                    }
                },
            });

            const productCard = document.querySelector('.js-productCard');
            const deliveryInfo = document.querySelector('.js-deliveryInfo');

            if (deliveryInfo) {
                const { productId } = productCard.dataset;

                Loader.start(deliveryInfo, { height: 100 });

                Request.get('/product/get-delivery-info/', { id: productId }, {
                    loader: false,
                    resolve: response => {
                        const data = response.info;

                        deliveryInfo.innerHTML = data.infoDataList;

                        Loader.stop(deliveryInfo);
                    },
                });
            }

            function addProductIdToUrl(event) {
                event.preventDefault();
                const parent = event.target.closest('.js-productCard');

                if (parent) {
                    window.history.replaceState(null, "", `#${parent.dataset.productId}`);
                    window.location.href = event.target.href;
                }
            }

            function goToProduct() {
                const url = window.location.href;
                const urlArr = url.split('#');

                if (urlArr[1]) {
                    const elem = document.querySelector(`[data-product-id="${urlArr[1]}"]`);

                    if (elem) {
                        const parent = elem.closest('.js-productCard');

                        if (parent) {
                            window.scrollBy({
                                top: parent.getBoundingClientRect().top - 20,
                                behavior: 'smooth',
                            });
                        }
                    }
                }
            }

            goToProduct();

            function getSizes(event) {
                const { productId } = event.target.closest('.js-productCard').dataset;

                Request.get('/product/sizes-info/', { id: productId }, {
                    loader: [document.body],
                    resolve: response => {
                        Modal.init({
                            content: response.widgets.sizesInfo.html,
                        }).open();

                        showBraSizeResult();
                        showClothesSizeResult();
                        showStockingsSizeResult();

                        document.dispatchEvent(new CustomEvent('reloadLazy'));
                        document.dispatchEvent(new CustomEvent('reloadCollapse'));
                    },
                });
            }

            function applyPromocode(event) {
                const self = event.target;
                const cartTotals = document.querySelector('.js-cartTotals');

                if (self.classList.contains('is-valid')) {
                    Request.sendForm(self, {
                        loader: [cartTotals],
                        resolve: response => {
                            if (response.success === true) {
                                cartTotals.innerHTML = response.totals;
                                document.dispatchEvent(new CustomEvent('reloadSticky'));
                                validation.bind(document.querySelector('.js-promocodeApply'));
                            } else {
                                const cartErrorElem = document.querySelector('.js-cartError');

                                if (cartErrorElem) {
                                    Message.show(cartErrorElem, response);
                                }
                            }
                        },
                    });
                }
            }

            function deletePromocode(event) {
                const self = event.target;
                const promoId = self.dataset.id;
                const cartTotals = document.querySelector('.js-cartTotals');

                Request.post('/promo/delete/', { id: promoId }, {
                    loader: [cartTotals],
                    resolve: response => {
                        if (response.success === true) {
                            cartTotals.innerHTML = response.totals;
                            document.dispatchEvent(new CustomEvent('reloadSticky'));
                            validation.bind(document.querySelector('.js-promocodeApply'));
                        } else {
                            const cartErrorElem = document.querySelector('.js-cartError');

                            if (cartErrorElem) {
                                Message.show(cartErrorElem, response);
                            }
                        }
                    },
                });
            }

            const checkoutContent = document.querySelector('.js-contentMain');
            let changeAddress = false;
            let deliveryMap;

            function reloadCheckout() {
                document.dispatchEvent(new CustomEvent('reloadDateTime'));
                document.dispatchEvent(new CustomEvent('reloadMask'));
                document.dispatchEvent(new CustomEvent('reloadFitting'));
                document.dispatchEvent(new CustomEvent('reloadLazy'));
                document.dispatchEvent(new CustomEvent('reloadSticky'));
                document.dispatchEvent(new CustomEvent('reloadContextMenu'));
                validation.bind(document.querySelector('.js-voucherApply'));
                validation.bind(document.querySelector('.js-balanceApply'));

                tailSelect('.js-select', {
                    height: 200,
                    locale: 'ru',
                });
            }

            function mapInit() {
                const mapPickers = Array.from(document.querySelectorAll('.js-pickupPoint'));

                if (mapPickers.length < 1) {
                    return;
                }

                deliveryMap = Map.renderMap({
                    element: document.querySelector('.js-mapSelfPickup'),
                    balloons: mapPickers.map(balloon => (
                        {
                            description: `${balloon.outerHTML}`,
                            location: {
                                lat: balloon.dataset.latitude,
                                long: balloon.dataset.longitude,
                            },
                            code: balloon.dataset.code,
                            delivery: balloon.dataset.delivery,
                        }
                    )),
                    center: ['55.755814', '37.617635'],
                    zoom: 4,
                    buttons: false,
                    dropdowns: false,
                    markupData: true,
                });
            }

            function checkoutUpdate(event, checkPoint, addressDismiss) {
                let cardId = {};
                let checkoutData = getFormData(document.querySelectorAll('.js-checkoutField:not(:disabled)'));
                const deliveryFieldsToFlush = getDeliveryFieldsToFlush(checkoutData);

                if (event.target.dataset.cardId) {
                    cardId = { 'checkout[cardId]': event.target.dataset.cardId };
                } else if (document.querySelector('.js-paymentCardId')) {
                    cardId = { 'checkout[cardId]': document.querySelector('.js-paymentCardId:checked').dataset.cardId };
                }

                checkoutData = Object.assign(checkoutData, cardId);

                const selfPickupForm = event.target.dataset.selfPickup === '1';

                if (event.target.dataset.selfPickup) {
                    checkoutData = Object.assign(checkoutData, { 'checkout[selfPickup]': event.target.dataset.selfPickup }, { flushFitting: 1 });
                    flushObjectFields(checkoutData, deliveryFieldsToFlush);
                }

                if (event.target.dataset.fitting) {
                    checkoutData = Object.assign(checkoutData, { 'checkout[isFitOn]': event.target.dataset.fitting });
                }

                if (changeAddress) {
                    checkoutData = Object.assign(checkoutData, { 'checkout[changeAddress]': 1 });
                    flushObjectFields(checkoutData, deliveryFieldsToFlush);
                }

                if (addressDismiss === true) {
                    const deleteFields = Object.getOwnPropertyNames(checkoutData).filter(fieldName => fieldName.match(/^useraddress\[/));

                    deleteFields.forEach(e => delete checkoutData[e]);
                }

                if (event.target.classList.contains('js-pickupPointSelect')) {
                    flushObjectFields(checkoutData, deliveryFieldsToFlush);
                }

                changeAddress = false;

                Request.post('/checkout/', checkoutData, {
                    resolve: response => {
                        if (!response.redirectUrl) {
                            checkoutContent.innerHTML = response.html;

                            reloadCheckout();

                            if (checkPoint === true) {
                                document.querySelector('.js-pointListWrap').classList.add('u-hidden');
                            }
                        } else {
                            location.href = response.redirectUrl;
                        }
                    },
                });
            }

            function saveData(event) {
                const form = event.target;
                const buttonSubmit = form.querySelector('button[type=submit]');

                if (form.classList.contains('is-valid')) {
                    Request.sendForm(form, {
                        loader: [buttonSubmit, { loaderButton: true, buttonWithOverlay: true }],
                        resolve: response => {
                            if (response.success === true) {
                                checkoutUpdate(event);
                            } else {
                                document.querySelectorAll('.js-checkoutError').forEach(item => {
                                    Message.show(item, response);
                                });
                            }
                        },
                    });
                }

                event.preventDefault();
            }

            function updateWidget(event, response) {
                const summary = document.querySelector('.js-checkoutTotals');
                const widget = event.target.closest('.js-paymentWidget');
                const widgetVoucher = document.querySelector('.js-voucherWidget');
                const widgetBalance = document.querySelector('.js-balanceWidget');

                widget.innerHTML = response.html;
                summary.innerHTML = response.summary;

                if (response.voucher) {
                    widgetVoucher.innerHTML = response.voucher;
                } else if (response.balancePrepayment) {
                    widgetBalance.innerHTML = response.balancePrepayment;
                }

                document.dispatchEvent(new CustomEvent('reloadCollapse'));
                document.dispatchEvent(new CustomEvent('reloadMask'));
                document.dispatchEvent(new CustomEvent('reloadLazy'));
                validation.bind(document.querySelector('.js-voucherApply'));
                validation.bind(document.querySelector('.js-balanceApply'));
            }

            function payVoucher(event) {
                const self = event.target;
                const checkoutData = getFormData(document.querySelectorAll('.js-checkoutField'));

                if (self.classList.contains('is-valid')) {
                    Request.sendForm(self, {
                        resolve: response => {
                            if (response.success === true) {
                                updateWidget(event, response);
                            } else {
                                Message.show(self, response);
                            }
                        },
                    }, checkoutData);
                }

                event.preventDefault();
            }

            function removeVoucher(event) {
                const self = event.target;
                const actionError = self.querySelector('.js-actionError');
                const { number } = self.dataset;
                const checkoutData = getFormData(document.querySelectorAll('.js-checkoutField'));
                let formData = { voucherNumber: number };

                formData = Object.assign(formData, checkoutData);

                Request.post('/checkout/remove-voucher-enrollment/', formData, {
                    resolve: response => {
                        if (response.success === true) {
                            updateWidget(event, response);
                        } else {
                            actionError.innerHTML = response.error;
                        }
                    },
                });

                event.preventDefault();
            }

            function removePrepayment(event) {
                const self = event.target;
                const actionError = self.querySelector('.js-actionError');
                const sum = self.dataset.number;
                const checkoutData = getFormData(document.querySelectorAll('.js-checkoutField'));
                let formData = { sum };

                formData = Object.assign(formData, checkoutData);

                Request.post('/checkout/remove-balance-prepayment/', formData, {
                    resolve: response => {
                        if (response.success === true) {
                            updateWidget(event, response);
                        } else {
                            actionError.innerHTML = response.error;
                        }
                    },
                });

                event.preventDefault();
            }

            function buy() {
                let cardId = null;
                let checkoutData = getFormData(document.querySelectorAll('.js-checkoutField:not(:disabled)'));

                if (document.querySelector('.js-paymentCardId')) {
                    cardId = { 'checkout[cardId]': document.querySelector('.js-paymentCardId:checked').dataset.cardId };
                }

                checkoutData = Object.assign(checkoutData, cardId);

                Request.post('/checkout/create/', checkoutData, {
                    resolve: response => {
                        if (response.success) {
                            location.href = response.redirectUrl;
                        } else {
                            initMask();

                            Message.show(document.querySelector('.js-checkoutError'), response);

                            if (response.userDataUpdated) {
                                const emailField = document.querySelector('.js-emailField');
                                if (emailField !== null) {
                                    emailField.classList.remove('js-checkoutField', 'js-customFieldValidate', 'js-emailField');
                                    emailField.classList.add('-disabled');
                                    emailField.removeAttribute('name');
                                    emailField.setAttribute('disabled', true);
                                }
                            }
                        }
                    },
                });
            }

            function validateCustom(isBuy, event) {
                const fields = document.querySelectorAll('.js-customFieldValidate:enabled');

                function testEmail(field, error, value) {
                    if (value.match(/^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/)) {
                        field.classList.remove('-invalid');
                        error.innerHTML = '';
                    } else {
                        field.classList.add('-invalid');
                        error.innerHTML = 'Введите корректный адрес';
                    }
                }

                fields.forEach(field => {
                    const error = field.nextElementSibling;

                    if (field.value === '') {
                        field.classList.add('-invalid');
                        // error.innerHTML = 'Обязательное поле';
                    } else if (field.type === 'email') {
                        testEmail(field, error, field.value);
                    } else {
                        field.classList.remove('-invalid');
                        // error.innerHTML = '';
                    }
                });

                if (!document.querySelectorAll('.js-customFieldValidate.-invalid').length) {
                    if (isBuy === true) {
                        buy();
                    } else {
                        checkoutUpdate(event);
                    }
                }
            }

            function editAddress(event) {
                const self = event.target;
                const addressId = self.dataset.id;
                const data = { id: addressId };

                Request.get('/checkout/edit-address/', addressId ? data : {}, {
                    loader: [document.body],
                    resolve: response => {
                        Modal.init({
                            content: response.widgets[Object.keys(response.widgets)[0]].html,
                            popoverElemClasses: '-medium',
                        }).open();

                        document.dispatchEvent(new CustomEvent('reloadAutocomplete'));
                        validation.bind(document.querySelector('.c-modal.-active form'));
                    },
                });
            }

            function deleteAddress(event) {
                const self = event.target;
                const addressId = self.dataset.id;
                const checkoutData = getFormData(document.querySelectorAll('.js-checkoutField'));
                let formData = {
                    'useraddress[id]': addressId,
                };

                const deliveryFieldsToFlush = getDeliveryFieldsToFlush(checkoutData);
                flushObjectFields(checkoutData, deliveryFieldsToFlush);

                formData = Object.assign(formData, checkoutData);

                Request.post('/checkout/delete-address/', formData, {
                    loader: [document.body],
                    resolve: response => {
                        checkoutContent.innerHTML = response.widgets[Object.keys(response.widgets)[0]].html;
                        reloadCheckout();
                    },
                });
            }

            function cardAction(event) {
                const self = event.target;
                const container = document.querySelector('.js-cardContainer');
                const { id } = self.dataset;
                const { action } = self.dataset;

                let url = null;

                if (action === 'default') {
                    url = '/account/default-card/';
                } else if (action === 'delete') {
                    url = '/account/delete-card/';
                }

                Request.post(url, { 'paymentcard[cardId]': id }, {
                    loader: true,
                    resolve: response => {
                        const content = response.widgets.cards.html;
                        const { errors } = response.widgets.cards;

                        if (content) {
                            container.innerHTML = content;
                        }

                        if (errors && errors.length) {
                            Message.show(container, errors);
                        }

                        document.dispatchEvent(new CustomEvent('reloadContextMenu'));
                    },
                });
            }

            function updateTime(self) {
                const option = self[self.selectedIndex];
                const container = self.closest('.js-time');
                const timeFrom = container.querySelector('.js-timeFrom');
                const timeTo = container.querySelector('.js-timeTo');

                timeFrom.value = option.dataset.timeFrom;
                timeTo.value = option.dataset.timeTo;
            }

            function initDateTime() {
                document.querySelectorAll('.js-selectDate').forEach(element => {
                    element.addEventListener('change', event => {
                        const self = event.target;
                        const date = self[self.selectedIndex].value;
                        const container = self.closest('.co__header-info');

                        if (container.querySelector('.js-timeContainer')) {
                            container.querySelectorAll('.js-timeContainer').forEach(elem => {
                                elem.classList.add('u-hidden');
                            });

                            container.querySelector(`.js-timeContainer[data-date="${date}"]`).classList.remove('u-hidden');
                        }
                    }, false);
                });

                document.querySelectorAll('.js-selectTime').forEach(element => {
                    element.addEventListener('change', event => {
                        updateTime(event.target);
                    }, false);
                });
            }

            function initFitting() {
                const self = document.querySelector('.js-selectFitting');
                if (self) {
                    self.addEventListener('change', event => {
                        if (self.dataset.fitting === '1') {
                            self.setAttribute('data-fitting', '0');
                        } else {
                            self.setAttribute('data-fitting', '1');
                        }

                        checkoutUpdate(event);
                    }, false);
                    if (self.getAttribute('checked') !== '') {
                        self.checked = false;
                    }
                }
            }

            function stepper(event) {
                const self = event.target;
                const { action } = self.dataset;
                const counter = self.parentNode.querySelector('.js-stepperCounter');

                let counterValue = parseInt(counter.value, 10);

                if (action === 'decrease') {
                    counterValue -= 1;
                    counter.value = counterValue;

                    if (counterValue <= 1) {
                        self.setAttribute('disabled', 'disabled');
                    }

                    self.parentNode.querySelector('.js-stepper[data-action="increase"]').removeAttribute('disabled');
                } else {
                    counterValue += 1;
                    counter.value = counterValue;

                    if (counterValue === 2) {
                        self.parentNode.querySelector('.js-stepper[data-action="decrease"]').removeAttribute('disabled');
                    }

                    if (counterValue === +counter.dataset.max) {
                        self.setAttribute('disabled', 'disabled');
                    }
                }

                cartUpdate(event);
            }

            function sizeSubscribe(event) {
                const self = event.target;
                const { productId } = document.querySelector('.js-productCard').dataset;
                const sizeId = document.querySelector('.js-productSizeSelect:checked');

                let data = {
                    id: productId,
                };

                if (sizeId) {
                    data = { stockItemIds: [sizeId.value], ...data };
                }

                Request.get('/product/show-subscription/', data, {
                    loader: [document.body],
                    resolve: response => {
                        Modal.init({
                            content: response.widgets.productSubscription.html,
                            popoverElemClasses: '-small',
                        }).open();

                        validation.bind(document.querySelector('.c-modal.-active form'));
                    },
                });

                event.preventDefault();
            }

            function openOneClick(event) {
                const self = event.target;

                Request.get('/checkout/fast/', {}, {
                    loader: [document.body],
                    resolve: response => {
                        Modal.init({
                            content: response.widgets.fastCheckout.html,
                            popoverElemClasses: '-small',
                        }).open();

                        validation.bind(document.querySelector('.c-modal.-active form'));
                        document.dispatchEvent(new CustomEvent('reloadMask'));
                    },
                });

                event.preventDefault();
            }

            const productSizeSelect = document.querySelector('.js-productSizeSelect');

            if (productSizeSelect) {
                const selectedSize = document.querySelector('.js-productSizeSelect:not(.-inactive)');

                if (selectedSize) {
                    changeSizePrice(selectedSize);
                }
            }

            function initExchange() {
                document.querySelectorAll('.js-productSizeExchange').forEach(element => {
                    element.addEventListener('change', event => {
                        cartExchange(event.target.value);
                    }, false);
                });
            }

            Map.renderMap({
                element: document.querySelector('.js-mapBoutiques'),
                buttons: true,
                dropdowns: true,
                balloons: [boutiques],
            });

            /* /boutiques/ */
            if (document.querySelector('.js-boutiquesPage')) {
                const boutiquesCopy = [...boutiques.items];
                const getBoutiques = city => boutiquesCopy.filter(x => x.city === city);

                const citiesList = document.querySelector('.js-citiesList');
                const citiesSelectMobile = document.querySelector('.js-citiesSelect');
                const cities = boutiquesCopy.map(x => x.city);
                /* MOBILE */
                cities.forEach(x => {
                    const element = document.createElement('option');
                    element.classList.add('js-cityOption');
                    element.innerHTML = x;
                    citiesSelectMobile.appendChild(element);
                });
                /* DESKTOP */
                cities.forEach(x => {
                    const element = document.createElement('span');
                    element.classList.add('u-marginRightX1');

                    const link = document.createElement('a');
                    link.classList.add('js-cityElement', 'u-textBigger', 'u-fontSemibold');
                    link.setAttribute('href', '#');

                    const cityHeading = document.createElement('span');
                    cityHeading.innerHTML = x;

                    link.appendChild(cityHeading);
                    element.appendChild(link);
                    citiesList.appendChild(element);
                });
                /* RENDER TAB CONTENT */
                const handleContentRender = city => {
                    const data = getBoutiques(city)[0];
                    const container = document.querySelector('.js-storeLocationsContainer');
                    container.innerHTML = '';

                    data.stores.forEach(x => {
                        const wrapperElement = document.createElement('div');
                        const title = document.createElement('h3');
                        const warning = document.createElement('p');
                        const address = document.createElement('p');
                        const phone = document.createElement('a');
                        const schedule = document.createElement('p');
                        const holidaysSchedule = document.createElement('p');
                        const mapLink = document.createElement('a');
                        const sanitizedPhone = x.phone.replaceAll(/[\s | \( | \)]/g, '');

                        const blockStructure = x.warning
                            ? [title, warning, address, phone, schedule, holidaysSchedule, mapLink]
                            : [title, address, phone, schedule, holidaysSchedule, mapLink];

                        /* innerHTML */
                        title.innerHTML = x.title;
                        warning.innerHTML = x.warning || '';
                        address.innerHTML = `Адрес: ${x.address}`;
                        phone.innerHTML = `Телефон: <span class="u-textUnderline">${x.phone}</span>`;
                        schedule.innerHTML = x.schedule;
                        holidaysSchedule.innerHTML = x.holidaysSchedule;
                        mapLink.innerHTML = '<svg class="c-icon"><use xlink:href="#pin"></use></svg><span class="a-text u-textUnderline">Показать на карте</span>';

                        /* Classes */
                        wrapperElement.classList.add('u-border', 'u-roundedCorners', 'u-paddingX2');
                        schedule.classList.add('u-marginTopX2', 'u-marginBottomX2');
                        holidaysSchedule.classList.add('u-marginTopX2', 'u-marginBottomX2');
                        mapLink.classList.add('c-iconGroup');

                        /* Attributes */
                        warning.setAttribute('style', 'color: red; font-size: bold');
                        mapLink.setAttribute('href', `https://yandex.ru/maps/?pt=${x.location.long},${x.location.lat}&z=17&l=map`);
                        mapLink.setAttribute('target', '_blank');
                        phone.setAttribute('href', `tel:${sanitizedPhone}`);

                        blockStructure.forEach(y => {
                            wrapperElement.appendChild(y);
                        });

                        container.appendChild(wrapperElement);
                    });
                };

                /* DEFAULTS TO MOSCOW */
                const currentCity = 'Москва';
                handleContentRender(currentCity);

                const tabs = document.querySelectorAll('.js-cityElement');
                tabs.forEach(x => x.classList.add('u-textMuted'));
                tabs[0].classList.remove('u-textMuted');

                const optionTabs = document.querySelectorAll('.js-cityOption');
                optionTabs[0].setAttribute('selected', true);

                const mergedTabs = [...tabs, ...optionTabs];

                /* TAB CLICKS */
                const handleClick = clickEvent => {
                    clickEvent.preventDefault();

                    tabs.forEach(x => x.classList.add('u-textMuted'));
                    optionTabs.forEach(x => x.removeAttribute('selected'));

                    mergedTabs.forEach(x => {
                        if (x.innerHTML === clickEvent.target.innerHTML || x.textContent === clickEvent.target.innerHTML) {
                            x.setAttribute('selected', true);
                            x.classList.remove('u-textMuted');
                        }
                    });
                    if (clickEvent.target.value) {
                        handleContentRender(clickEvent.target.value);
                    } else {
                        handleContentRender(clickEvent.target.textContent);
                    }
                };
                mergedTabs.forEach(x => x.addEventListener('click', handleClick));
                citiesSelectMobile.addEventListener('change', handleClick);
            }

            function initProductCarousel() {
                document.querySelectorAll('.js-productCarouselX3').forEach(element => {
                    element.swiperInstance = new Swiper(element, {
                        slidesPerView: 1,
                        slidesPerGroup: 2,
                        watchOverflow: true,
                        wrapperClass: 'js-sliderWrapper',
                        slideClass: 'js-sliderSlide',
                        navigation: {
                            nextEl: element.parentNode.querySelector('.js-sliderNext'),
                            prevEl: element.parentNode.querySelector('.js-sliderPrev'),
                        },
                        scrollbar: {
                            el: element.parentNode.querySelector('.js-sliderScrollbar'),
                            draggable: true,
                        },
                        breakpointsInverse: true,
                        breakpoints: {
                            576: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                            },
                            320: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                        },
                    });
                });

                document.querySelectorAll('.js-productCarouselX4').forEach(element => {
                    element.swiperInstance = new Swiper(element, {
                        slidesPerView: 1,
                        slidesPerGroup: 2,
                        watchOverflow: true,
                        wrapperClass: 'js-sliderWrapper',
                        slideClass: 'js-sliderSlide',
                        navigation: {
                            nextEl: element.parentNode.querySelector('.js-sliderNext'),
                            prevEl: element.parentNode.querySelector('.js-sliderPrev'),
                        },
                        scrollbar: {
                            el: element.parentNode.querySelector('.js-sliderScrollbar'),
                            draggable: true,
                        },
                        breakpointsInverse: true,
                        breakpoints: {
                            576: {
                                slidesPerView: 4,
                                slidesPerGroup: 4,
                            },
                            320: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                        },
                    });
                });
                document.querySelectorAll('.js-productCarouselX6').forEach(element => {
                    element.swiperInstance = new Swiper(element, {
                        slidesPerView: 1,
                        slidesPerGroup: 2,
                        watchOverflow: true,
                        wrapperClass: 'js-sliderWrapper',
                        slideClass: 'js-sliderSlide',
                        navigation: {
                            nextEl: element.parentNode.querySelector('.js-sliderNext'),
                            prevEl: element.parentNode.querySelector('.js-sliderPrev'),
                        },
                        scrollbar: {
                            el: element.parentNode.querySelector('.js-sliderScrollbar'),
                            draggable: true,
                        },
                        breakpointsInverse: true,
                        breakpoints: {
                            576: {
                                slidesPerView: 6,
                                slidesPerGroup: 6,
                            },
                            320: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                        },
                    });
                });

                document.querySelectorAll('.js-productCarouselX6by3').forEach(element => {
                    element.swiperInstance = new Swiper(element, {
                        slidesPerView: 1,
                        slidesPerGroup: 2,
                        watchOverflow: true,
                        wrapperClass: 'js-sliderWrapper',
                        slideClass: 'js-sliderSlide',
                        navigation: {
                            nextEl: element.parentNode.querySelector('.js-sliderNext'),
                            prevEl: element.parentNode.querySelector('.js-sliderPrev'),
                        },
                        scrollbar: {
                            el: element.parentNode.querySelector('.js-sliderScrollbar'),
                            draggable: true,
                        },
                        breakpointsInverse: true,
                        breakpoints: {
                            576: {
                                slidesPerView: 6,
                                slidesPerGroup: 6,
                            },
                            320: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                            },
                        },
                    });
                });
            }

            function initMask() {
                Array.from(document.querySelectorAll('.js-maskZipcode')).forEach(element => {
                    element.zipcodeMask = new IMask(element, {
                        mask: /^[1-6]\d{0,5}$/,
                    });
                });

                Array.from(document.querySelectorAll('.js-maskCode')).forEach(element => {
                    element.zipcodeMask = new IMask(element, {
                        mask: '0000',
                    });
                });

                Array.from(document.querySelectorAll('.js-maskPhoneFixed')).forEach(element => {
                    element.zipcodeMask = new IMask(element, {
                        mask: '+{7} (000) 000 00 00',
                        lazy: false,
                    });
                });

                Array.from(document.querySelectorAll('.js-maskPhone')).forEach(element => {
                    element.zipcodeMask = new IMask(element, {
                        mask: '+{7} (000) 000 00 00',
                        lazy: element.dataset.noLazy === undefined,
                    });
                });

                Array.from(document.querySelectorAll('.js-maskDate')).forEach(element => {
                    element.zipcodeMask = new IMask(element, {
                        mask: Date,
                        min: new Date(1900, 0, 1),
                        max: new Date(2030, 0, 1),
                        lazy: false,
                    });
                });

                Array.from(document.querySelectorAll('.js-maskGiftCard')).forEach(element => {
                    element.giftCardMask = new IMask(element, {
                        mask: '******',
                    });
                });
            }

            function validateAccountSettingsBirthday() {
                /* /account/settings/ */
                /* формат даты для фронта YYYY-MM-DD (ISO) */
                const birthdayFE = document.querySelector('.js-birthDate');
                /* формат даты для бэка DD.MM.YYYY */
                const birthdayBE = document.getElementsByName('updatedata[birthday]')[0];

                const formatWithZero = num => {
                    const str = String(num);
                    if (str.length === 1) {
                        return '0'.concat(str);
                    }
                    return str;
                };

                /* Максимальное значение поля даты рождения = сегодняшняя дата */
                const now = new Date();
                const nowYear = now.getFullYear();
                const nowMonth = formatWithZero(now.getMonth() + 1); // Months start from 0
                const nowDay = formatWithZero(now.getDate());

                birthdayFE.max = `${nowYear}-${nowMonth}-${nowDay}`;
                birthdayFE.min = `${nowYear - 100}-${nowMonth}-${nowDay}`;

                /* Вставка бэкэнд значения на фронте */
                if (birthdayBE.value) {
                    const dateArray = birthdayBE.value.split('.');
                    const year = dateArray[2];
                    const month = dateArray[1];
                    const day = dateArray[0];

                    birthdayFE.value = `${year}-${month}-${day}`;
                    birthdayFE.defaultValue = birthdayFE.value;
                }

                /* При расфокусе поля даты, форматирование и вставка введённого значения в поле для бэкэнда */
                document.querySelector('.js-birthDate').addEventListener('blur', event => {
                    if (event.target.value) {
                        const inputArr = event.target.value.split('-');
                        const year = inputArr[0];
                        const month = inputArr[1];
                        const day = inputArr[2];

                        birthdayBE.value = `${day}.${month}.${year}`;
                        birthdayBE.defaultValue = birthdayBE.value;
                    } else {
                        birthdayBE.value = '';
                        birthdayBE.defaultValue = birthdayBE.value;
                    }
                });
            }

            /* Валидация даты рождения в /account/settings/ */
            if (document.getElementsByName('updatedata[birthday]')[0]) {
                validateAccountSettingsBirthday();
            }

            initDateTime();
            initFitting();
            initExchange();
            initProductCarousel();
            initMask();

            const userMenu = Tooltip.init({
                trigerElem: document.querySelector('.js-userMenu'),
                popoverElem: document.querySelector('.js-userMenuContent'),
            });

            function initContextMenu() {
                document.querySelectorAll('.js-contextMenu').forEach(element => {
                    Tooltip.init({
                        trigerElem: element,
                        popoverElem: element.parentNode.querySelector('.js-contextMenuContent'),
                    });
                });
            }

            initContextMenu();

            MediaQuery.set('screen and (max-width:1279.98px)', {
                match: () => {
                    document.addEventListener('click', event => {
                        if (event.target.matches('.js-mainMenuDropdown')) {
                            event.preventDefault();
                        }
                    });

                    if (document.querySelector('[data-collapse-content]')) {
                        collapse.close('footerTab1', false, false);
                        collapse.close('footerTab2', false, false);
                    }
                },
            });

            MediaQuery.set('screen and (min-width:1279.98px)', {
                match: () => {
                    if (document.querySelector('[data-collapse-content]')) {
                        collapse.open('footerTab1', false, false);
                        collapse.open('footerTab2', false, false);
                    }
                },
            });

            function initSearchInput(element) {
                if (!element) {
                    return;
                }

                element.addEventListener('input', () => {
                    if (element.value.length < 2) {
                        element.classList.remove('-loading');
                    }
                });

                autocompleteCustom({
                    input: element,
                    minLength: 3,
                    debounceWaitMs: 400,
                    className: 'c-autocomplete',
                    topOffset: 1,
                    // notRenderScroll: true,
                    fetch(text, update) {
                        element.classList.add('-loading');
                        const fixedList = [
                            {id: null, title: element.value, suggest: '', url: '/product/search/?query=' + element.value}
                        ];
                        Request.get('/search/suggest/', { query: element.value, gender: 2 }, {
                            loader: false,
                            resolve: response => {
                                const suggestList = fixedList.concat(response.brand.concat(response.category));
                                update(suggestList);
                                element.classList.remove('-loading');
                            },
                            error: () => {
                                element.classList.remove('-loading');
                            },
                        });

                        if (text.length < 3) {
                            element.classList.remove('-loading');
                        }
                    },
                    customUpdate(items, container, input) {
                        renderSearchAutocomplete(items, container, input);
                    },
                });
            }

            initSearchInput(document.querySelector('.js-searchFormField'));

            const searchModal = Modal.init({
                popoverElem: document.querySelector('.js-searchModal'),
                popoverElemClasses: '-medium',
                onOpen: () => {
                    document.querySelector('.js-searchFormField').focus();
                },
            });

            const citySearchField = document.querySelector('.js-citySearchField');

            const cityModal = Modal.init({
                popoverElem: document.querySelector('.js-cityModal'),
                popoverElemClasses: '-small',
            });

            function citySelect(id) {
                Request.post('/site/city/', { cityId: id }, {
                    loader: false,
                    resolve: response => {
                        cityModal.close();

                        if (response.redirectUrl) {
                            window.location = response.redirectUrl;
                        }
                    },
                });
            }

            if (citySearchField) {
                autocomplete({
                    input: citySearchField,
                    minLength: 1,
                    debounceWaitMs: 200,
                    emptyMsg: 'Город не найден',
                    onSelect(item) {
                        citySelect(item.city.id);
                    },
                    fetch(text, update) {
                        const citySearchValue = citySearchField.value;

                        citySearchField.classList.add('-loading');

                        Request.post('/site/search-city/', { query: citySearchValue }, {
                            loader: false,
                            resolve: response => {
                                citySearchField.classList.remove('-loading');
                                update(response);
                            },
                            error: () => {
                                citySearchField.classList.remove('-loading');
                            },
                        });
                    },
                });
            }

            function getModalContent(event) {
                const self = event.target;

                Request.get(self.dataset.url, {}, {
                    loader: [modalContent],
                    resolve: response => {
                        modalContent.innerHTML = response.widgets[Object.keys(response.widgets)[0]].html;

                        document.querySelectorAll('.c-modal.-active form').forEach(element => {
                            validation.bind(element);
                        });
                    },
                });
            }

            function toggleReveal(self) {
                const container = self.closest('.js-revealContainer');
                const staticBlock = container.querySelector('.js-staticBlock');
                const editableBLock = container.querySelector('.js-editableBlock');
                const message = container.querySelector('.js-textSuccess, .js-textError');

                staticBlock.classList.toggle('u-hidden');
                editableBLock.classList.toggle('u-hidden');

                if (message) {
                    message.remove();
                }
            }

            const customSelect = document.querySelectorAll('.js-customSelect')

            if (customSelect.length !== 0) {
                customSelect.forEach(select => {
                    const selectElem = select.getElementsByTagName("select")[0];
                    const selectLength = selectElem.length;

                    let selectedItem = document.createElement('div');
                    selectedItem.setAttribute("class", "c-setСhoose__selectSelected");
                    selectedItem.innerHTML = selectElem.options[selectElem.selectedIndex].innerHTML;
                    select.appendChild(selectedItem);

                    let container = document.createElement('div');
                    container.setAttribute("class", "c-setСhoose__selectItems c-setСhoose__selectHide");

                    for (let i = 1; i < selectLength; i++) {
                        let selectContainer = document.createElement("DIV");
                        selectContainer.innerHTML = selectElem.options[i].innerHTML;

                        if (selectElem.options[i].hasAttribute('disabled')) {
                            selectContainer.setAttribute('disabled', '');
                        }

                        selectContainer.addEventListener("click", event => {
                            if (event.target.hasAttribute('disabled')) {
                                return;
                            }

                            let selectNew = event.target.parentNode.parentNode.getElementsByTagName("select")[0];
                            const selectNewLength = selectNew.length;
                            let siblingElement = event.target.parentNode.previousSibling;

                            // const productCard = element.closest('.js-productCard');
                            // const stockId = productCard.querySelector('.js-stockId');
                            // stockId.value = element.value;

                            for (let j = 0; j < selectNewLength; j++) {
                                if (selectNew.options[j].innerHTML === event.target.innerHTML) {
                                    const productCard = selectNew.closest('.js-productCard');
                                    const stockId = productCard.querySelector('.js-stockId');
                                    const stockPrice = productCard.querySelector('.js-stockPrice');
                                    stockId.value = selectNew.options[j].value;
                                    stockPrice.value = selectNew.options[j].dataset.price;
                                    selectNew.selectedIndex = j;
                                    siblingElement.innerHTML = event.target.innerHTML;
                                    let asSelected = event.target.parentNode.getElementsByClassName("c-setСhoose__selectAsSelected");
                                    const asSelectedLength = asSelected.length;
                                    for (let k = 0; k < asSelectedLength; k++) {
                                        asSelected[k].removeAttribute("class");
                                    }
                                    event.target.setAttribute("class", "c-setСhoose__selectAsSelected");
                                    break;
                                }
                            }
                            siblingElement.click();
                        });
                        container.appendChild(selectContainer);
                    }
                    select.appendChild(container);
                    selectedItem.addEventListener('click', event => {
                        event.stopPropagation();
                        closeAllSelect(event.target);
                        event.target.nextSibling.classList.toggle("c-setСhoose__selectHide");
                        // this.classList.toggle("select-arrow-active");
                    });
                });

                function closeAllSelect(elem) {
                    let arrNo = [];
                    let selectedItems = document.getElementsByClassName("c-setСhoose__selectItems");
                    let selectSelectedItems = document.getElementsByClassName("c-setСhoose__selectSelected");
                    let selectedItemsLength = selectedItems.length;
                    let selectSelectedItemsLength = selectSelectedItems.length;
                    for (let i = 0; i < selectSelectedItemsLength; i++) {
                        if (elem === selectSelectedItems[i]) {
                            arrNo.push(i)
                        } else {
                            selectSelectedItems[i].classList.remove("select-arrow-active");
                        }
                    }
                    for (let i = 0; i < selectedItemsLength; i++) {
                        if (arrNo.indexOf(i)) {
                            selectedItems[i].classList.add("c-setСhoose__selectHide");
                        }
                    }
                }
            }

            const cartWrapper = document.querySelector('.js-cartCollectionWrapper');

            if (cartWrapper) {
                const cart = sessionStorage.getItem('cartCollection');

                if (cart) {
                    const cartParse = JSON.parse(cart);

                    const cartQuantity = cartParse.reduce((acc, item) => {
                        return acc + +item.quantity;
                    }, 0);
                    const cartTotalPrice = cartParse.reduce((acc, item) => {
                        return acc + +item.price * +item.quantity;
                    }, 0);

                    convertingStringQuantityAndPrice(cartTotalPrice, cartQuantity);
                }
            }

            document.querySelectorAll('input, textarea').forEach(element => {
                element.addEventListener('focus', () => {
                    if (element.classList.contains('-invalid')) {
                        element.classList.remove('-invalid');
                    }
                });
            });

            document.addEventListener('click', event => {
                if (event.target.matches('.js-applyFiltersButton') || event.target.matches('.js-filtersApply')) {
                    filter.loadFilters(event);
                }

                if (event.target.matches('.js-promocodeDelete')) {
                    deletePromocode(event);
                }

                if (event.target.matches('.js-cardAction')) {
                    cardAction(event);
                }

                if (event.target.matches('.js-popoverPlace .js-modalPrev')) {
                    // filter.loadPrevModal(event);
                    filter.UI.openedForm.closePopover();
                }

                if (event.target.matches('.js-openFiltersModal')) {
                    if (filter.UI && filter.UI.mobileModal) {
                        filter.UI.mobileModal.open();
                    }
                }

                if (event.target.matches('.js-editReveal')) {
                    toggleReveal(event.target);
                }

                if (event.target.matches('.js-showPickupPoints')) {
                    Modal.init({
                        content: document.querySelector('.js-pointListWrap'),
                    }).open();

                    mapInit();
                }

                if (event.target.matches('.js-hidePickupPoints')) {
                    const modal = document.querySelector('.c-modal.-active');

                    modal.querySelector('.js-modalClose').click();
                }

                if (event.target.matches('.js-socialAuth')) {
                    socialAuth(event, config);
                }

                if (event.target.matches('.js-stepper')) {
                    stepper(event);
                }

                if (event.target.matches('.js-catalogPage')) {
                    filter.loadPage(event);
                }

                if (event.target.matches('.js-catalogLoad')) {
                    filter.loadMore(event);
                }

                if (event.target.matches('.js-filtersClear')) {
                    filter.loadClear(event);
                }

                // if (event.target.matches('.js-catalogNavItem')) {
                //     filter.loadSubcategory(false, event);
                // }

                if (event.target.matches('.js-clearFiltersType')) {
                    filter.removeType(event);
                }

                if (event.target.matches('.js-getModalContent')) {
                    getModalContent(event);
                }

                if (event.target.matches('.js-changePasswordToggle')) {
                    togglePasswordForm();
                }

                if (event.target.matches('.js-citySelect')) {
                    citySelect(event.target.dataset.id);
                }

                if (event.target.matches('.js-openSizeSubscribe')) {
                    sizeSubscribe(event);
                }

                // if (event.target.matches('.js-mainMenuDropdown')) {
                //     event.preventDefault();
                // }

                if (event.target.matches('.js-showMainMenu')) {
                    Modal.init({
                        popoverElem: document.querySelector('.js-mainMenuModal'),
                        content: document.querySelector('.js-mainMenuWrapper'),
                        popoverElemClasses: '-sidepage',
                        dontCloseOffsetEvent: true,
                    }).open();
                }

                if (event.target.matches('.js-showStoreAvailability')) {
                    Modal.init({
                        popoverElem: document.querySelector('.js-storeAvailabilityWrapper'),
                    }).open();
                }

                if (event.target.matches('.js-renderChooseSizes')) {
                    event.preventDefault();
                    Sets.init({
                        popoverElem: document.querySelector('.js-chooseSizesWrapper'),
                        openPopoverClass: '',
                        openPopoverRemoveClass: 'u-hidden',
                        trigerElem: document.querySelector('.js-chooseSizesInfo'),
                        openTrigerElemClass: 'u-hidden',
                    }).open();
                }

                if (event.target.matches('.js-showFormVoucher')) {
                    Modal.init({
                        popoverElem: document.querySelector('.js-modalFormVoucher'),
                        openPopoverClass: '-medium',
                    }).open();
                }

                if (event.target.matches('.js-showModalDolyameInfo')) {
                    Modal.init({
                        popoverElem: document.querySelector('.js-modalDolyameInfo'),
                        openPopoverClass: '-medium',
                    }).open();
                }

                if (event.target.matches('.js-userSignin')) {
                    modalSignin.open();

                    const redirectUrl = event.target.dataset.redirectUrl
                        ? event.target.dataset.redirectUrl
                        : window.location.pathname + window.location.search;
                    const source = event.target.dataset.source ? event.target.dataset.source : null;

                    Request.get('/account/signin/', { redirectUrl, source }, {
                        loader: [modalContent],
                        resolve: response => {
                            if (response.redirectUrl) {
                                modalSignin.close();
                                window.location = response.redirectUrl;
                            } else {
                                modalContent.innerHTML = response.confirmPhoneWidget;
                                initMask();
                                document.querySelector('.js-signinPhone').focus();
                                document.querySelectorAll('.c-modal.-active form').forEach(element => {
                                    validation.bind(element);
                                });
                            }
                        },
                    });
                }

                if (event.target.matches('.js-formSigninStepBack')) {
                    const form = event.target.closest('.js-submitAuthForm');

                    form.querySelector('.js-formSigninStepOne').classList.remove('u-hidden');

                    if (form.querySelector('.js-formSigninStepTwo')) {
                        form.querySelector('.js-formSigninStepTwo').remove();
                    }

                    if (form.querySelector('.js-formSigninStepRecaptcha')) {
                        form.querySelector('.js-formSigninStepRecaptcha').remove();
                    }

                    initMask();
                    document.querySelector('.js-signinPhone').focus();
                }

                if (event.target.matches('.js-openImageFullscreen')) {
                    const { index } = event.target.dataset;

                    Modal.init({
                        popoverElemClasses: '-fullscreen',
                        content: document.querySelector('.js-imageFullscreenWrapper').innerHTML,
                    }).open();

                    initImageFullscreen(index);
                }

                if (event.target.matches('.js-openSizesModal')) {
                    getSizes(event);
                }

                if (event.target.matches('.js-addToCart')) {
                    cartAdd(event);
                }

                if (event.target.matches('.js-addArrayToCart')) {
                    cartAddArray(event);
                }

                if (event.target.matches('.js-addToMemoryCollection')) {
                    memoryAddCollection(event);
                    // const modal = Modal.init({
                    //     content: `
                    //         <div>
                    //             <div class="c-modal__header">
                    //                 <div class="c-modal__close js-modalClose">
                    //                     <svg class="c-icon">
                    //                         <use xlink:href="#close"></use>
                    //                     </svg>
                    //                 </div>
                    //             </div>
                    //             <div class="c-modal__body l-flex -middle -center -noGap">
                    //                 <p class="u-textBigger">Товар добавлен в корзину</p>
                    //             </div>
                    //             <div class="c-modal__footer"></div>
                    //         </div>
                    //     `,
                    //     popoverElemClasses: '-small',
                    //     onOpen: () => {
                    //         setTimeout(() => {
                    //             modal.close();
                    //         }, 1000);
                    //     }
                    // });
                    // modal.open();
                }

                if (event.target.matches('.js-addToCartFast')) {
                    cartAddFast(event);
                }

                if (event.target.matches('.js-dismissAddress')) {
                    checkoutUpdate(event, false, true);
                }

                if (event.target.matches('.js-removeVoucher')) {
                    removeVoucher(event);
                }

                if (event.target.matches('.js-removePrepayment')) {
                    removePrepayment(event);
                }

                // if (event.target.matches('.js-showPointsList')) {
                //     document.querySelector('.js-pointListWrap').classList.remove('u-hidden');
                //     document.querySelector('.js-pointSelectedWrap').classList.add('u-hidden');
                // }

                // if (event.target.matches('.js-hidePointsList')) {
                // document.querySelector('.js-pointListWrap').classList.add('u-hidden');
                // document.querySelector('.js-pointSelectedWrap').classList.remove('u-hidden');
                // deliveryMap.closeAllBallons();

                // }

                if (event.target.matches('.js-checkoutUpdate:not(.-active)')) {
                    checkoutUpdate(event);
                }

                if (event.target.matches('.js-editAddress')) {
                    editAddress(event);
                }

                if (event.target.matches('.js-deleteAddress')) {
                    deleteAddress(event);
                }

                if (event.target.matches('.js-orderCreate')) {
                    validateCustom(true, event);
                }

                if (event.target.matches('.js-addressSave')) {
                    validateCustom(false, event);
                }

                if (event.target.matches('.js-showRecipient')) {
                    const formWrapper = document.querySelector('.js-recipientForm');

                    formWrapper.querySelectorAll('.js-checkoutField').forEach(field => field.removeAttribute('disabled'));
                    document.querySelector('.js-recipientHeader').classList.add('u-hidden');
                    document.querySelector('.js-recipientForm').classList.remove('u-hidden');
                }

                if (event.target.matches('.js-hideRecipient')) {
                    const formWrapper = document.querySelector('.js-recipientForm');
                    const formFields = formWrapper.querySelectorAll('.js-checkoutField');

                    document.querySelector('.js-recipientHeader').classList.remove('u-hidden');
                    document.querySelector('.js-recipientForm').classList.add('u-hidden');
                    formFields.forEach(field => field.setAttribute('disabled', true));

                    formFields.forEach(field => {
                        // const error = field.nextElementSibling;

                        field.value = '';
                        field.classList.remove('-invalid');
                        // error.innerHTML = '';
                    });
                }

                if (event.target.matches('.js-pickupPointSelect')) {
                    const self = event.target.closest('.js-pickupPoint');
                    document.querySelector('.js-deliveryId').value = self.dataset.delivery;
                    document.querySelector('.js-pickupPointCode').value = self.dataset.code;
                    const checkPoint = true;
                    const modal = document.querySelector('.c-modal.-active');

                    modal.querySelector('.js-modalClose').click();

                    checkoutUpdate(event, checkPoint);
                }

                if (event.target.matches('.js-deleteCart')) {
                    cartDelete(event);
                }

                if (event.target.matches('.js-oneClick')) {
                    openOneClick(event);
                }

                if (event.target.matches('.js-openCityModal')) {
                    cityModal.open();
                }

                if (event.target.matches('.js-searchFormOpen')) {
                    searchModal.open();
                }

                if (event.target.matches('.js-updateWishlist')) {
                    wishlistUpdate(event);
                }

                if (event.target.matches('.js-checkoutDateTimeSelectors .dropdown-option')) {
                    checkoutUpdate(event);
                }

                if (event.target.matches('.js-openDetailInfo')) {
                    Modal.init({
                        content: event.target.closest('.js-productCard').querySelector('.js-detailInfoWrapper'),
                        popoverElemClasses: '-medium',
                    }).open();
                }

                if (event.target.matches('.js-productCardLink')) {
                    addProductIdToUrl(event);
                }
            }, false);

            document.addEventListener('change', event => {
                if (event.target.matches('.js-productSizeSelect')) {
                    changeSizePrice(event.target);
                }

                if (event.target.matches('.js-selectSizeSelect')) {
                    changeSizePriceSelect(event.target);
                }

                if (event.target.matches('.js-selectSort')) {
                    filter.loadSort(event);
                }

                if (event.target.matches('.js-setSubscriptions')) {
                    const form = event.target.closest('form');

                    Request.sendForm(form, {
                        loader: [form],
                        resolve: response => {
                            // form.outerHTML = response.widgets[Object.keys(response.widgets)[0]].html;
                        },
                    });
                }

                if (event.target.matches('.js-popoverPlace .js-catalogNavItem')) {
                    filter.loadSubcategory(true, event);
                }

                if (event.target.matches('.js-promocodeCheck')) {
                    document.querySelector('.js-promocodeCheckContent').classList.toggle('u-hidden');
                }

                if (event.target.matches('.js-voucherCheck')) {
                    document.querySelector('.js-voucherCheckContent').classList.toggle('u-hidden');
                }

                if (event.target.matches('.js-balanceCheck')) {
                    document.querySelector('.js-balanceCheckContent').classList.toggle('u-hidden');
                }

                if (event.target.matches('.js-selectAddress')) {
                    changeAddress = true;
                    checkoutUpdate(event);
                }
            });

            document.addEventListener('submit', event => {
                if (event.target.matches('.js-submitForm')) {
                    submitForm(event);
                }

                if (event.target.matches('.js-submitAuthForm')) {
                    submitAuthForm(event);
                }

                if (event.target.matches('.js-saveAddress')) {
                    saveAddress(event);
                }

                if (event.target.matches('.js-formVoucher')) {
                    manageVoucher(event);
                }

                if (event.target.matches('.js-promocodeApply')) {
                    applyPromocode(event);
                }

                if (event.target.matches('.js-voucherApply')) {
                    payVoucher(event);
                }

                if (event.target.matches('.js-balanceApply')) {
                    payVoucher(event);
                }
            }, false);

            window.addEventListener('orientationchange', () => {
                MediaQuery.set('screen and (min-width:1279.98px)', {
                    match: () => {
                        if (filter.UI && filter.UI.mobileModal) {
                            filter.UI.mobileModal.close();
                        }

                        if (cityModal) {
                            cityModal.close();
                        }
                    },
                });
            });

            document.addEventListener('reloadSticky', () => {
                initSticky();
            });

            document.addEventListener('reloadContextMenu', () => {
                initContextMenu();
            });

            document.addEventListener('reloadLazy', () => {
                lazy.update();
            });

            document.addEventListener('reloadMask', () => {
                initMask();
            });

            document.addEventListener('reloadAutocomplete', () => {
                addressAutocomplete();
            });

            document.addEventListener('reloadDateTime', () => {
                initDateTime();
            });

            document.addEventListener('reloadFitting', () => {
                initFitting();
            });

            document.addEventListener('initExchange', () => {
                initExchange();
            });

            document.addEventListener('reloadProductCarousel', () => {
                initProductCarousel();
            });

            document.addEventListener('lazyLoadImage', event => {
                if (event.detail && event.detail.elem) {
                    const parent = event.detail.elem.closest('.js-productCard');
                    const revealImage = parent.querySelector('.js-lazyReveal');

                    lazy.load(revealImage);

                    revealImage.classList.remove('u-hidden');
                }
            });

            document.addEventListener('loadLazyAll', () => {
                lazy.loadAll();
            });

            if (document.querySelector('.js-dateInput')) {
                const currentDate = new Date();
                const visibleDate = document.querySelector('.js-dateInput');
                const formatWithZero = num => (num.toString().length === 1 ? '0'.concat(num) : num);

                visibleDate.min = `${currentDate.toISOString().slice(0, 10)}`;
                visibleDate.max = `${new Date().getFullYear() + 10}-${currentDate.toISOString().slice(5, 10)}`;
                visibleDate.value = `${new Date().getFullYear()}-${currentDate.toISOString().slice(5, 10)}`;

                visibleDate.addEventListener('blur', () => {
                    const input = new Date(visibleDate.value);
                    const formatted = `${formatWithZero(input.getDate())}-${formatWithZero(input.getMonth() + 1)}-${input.getFullYear()}`;

                    const dateForServer = document.getElementsByName('createvoucher[sendDate]')[0];
                    dateForServer.value = formatted;
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    });

    window.addEventListener('load', () => {
        try {
            Tracking.start('all');
        } catch (error) {
            Sentry.captureException(error);
        }
    });
}());
